/**
 * Based on: https://material-ui.com/getting-started/page-layout-examples/blog/
 */

import AttendingIcon from './AttendingIcon'
import CircularProgress from '@mui/material/CircularProgress';
import GuestAvatar from './GuestAvatar';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import React, { useEffect, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import { AttendingStatus } from './models/AttendingStatus';
import { EventParams } from './models/EventParams';
import { Guest, getGuestName } from './models/Guest';
import { useParams } from 'react-router-dom';

const statuses = [
  { key: AttendingStatus.ATTENDING, title: 'Guests Attending' },
  { key: AttendingStatus.NOT_ATTENDING, title: 'Not Attending' },
  { key: AttendingStatus.MAYBE, title: 'Maybe' },
  { key: AttendingStatus.AWAITING_RESPONSE, title: 'Awaiting Response' },
];
const dateStringOpts = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
const renderDateString = (dateVal: string) => {
  return new Date(dateVal).toLocaleDateString('en-US', dateStringOpts)
}

interface IEventGuestsProps { userGuest: Guest };
type GuestsByStatus = Record<AttendingStatus, Array<Guest>>;

function EventGuests(props: IEventGuestsProps) {
  const [guestsByStatus, setGuestsByStatus] = useState<GuestsByStatus | null>(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams<EventParams>();

  useEffect(() => {
    fetch(`/api/event/${id}/guests`,
      { credentials: 'include', })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`${response.status}`);
        }
        return response.json();
      })
      .then((guestsByStatusObject) => void setGuestsByStatus(guestsByStatusObject))
      .finally(() => void setLoading(false));
  }, [id]);

  // useRef holds the previous value of attending:
  // https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
  const prevAttending = useRef(props.userGuest.attending);

  // Recomputes guestsByStatus when props.userGuest changes so we don't need to
  // make another request to the server.
  useEffect(() => {
    if (!guestsByStatus) return;
    const previousAttending = prevAttending.current;
    const newAttending = props.userGuest.attending;
    if (previousAttending === newAttending) return;

    // Clone the guestsByStatus object so useState will process it as an
    // update.
    const newGuestsByStatus = { ...guestsByStatus };

    // Find the guest object matching the user in the previous list and remove
    // it.
    const prevGuestList = guestsByStatus[previousAttending];
    const i = prevGuestList.findIndex(
      (guest: Guest) => guest.email === props.userGuest.email)
    if (i !== -1) {
      newGuestsByStatus[prevAttending.current].splice(i, 1);
    }
    newGuestsByStatus[newAttending].unshift({ ...props.userGuest });

    // Update the cached value.
    prevAttending.current = newAttending;

    setGuestsByStatus(newGuestsByStatus);
  }, [guestsByStatus, props.userGuest]);

  if (loading) return (
    <CircularProgress color="secondary" />
  )
  return (
    <Paper>
      {statuses.map(
        ({ key, title }) => (
          <div key={key}>
            {guestsByStatus[key].length > 0 &&
              <div>
                <Typography component="h5" variant="h5" color="inherit" gutterBottom>
                  {title} <Typography display="inline">({guestsByStatus[key].length})</Typography>
                </Typography>
                <List dense={true}>
                  {guestsByStatus[key].map((guest: any) => (
                    <ListItem key={guest.email}>
                      <ListItemAvatar>
                        <GuestAvatar guest={guest} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={getGuestName(guest)}
                        secondary={guest.modified && renderDateString(guest.modified)}
                      />
                      <ListItemIcon><AttendingIcon attending={guest.attending} /></ListItemIcon>
                    </ListItem>
                  ))}
                </List>
              </div>
            }
          </div>
        )
      )}
    </Paper>
  );
}

export default EventGuests;
