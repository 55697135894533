import CancelIcon from '@mui/icons-material/Cancel';
import EventForm from './EventForm';
import EventGuestsEdit from './EventGuestsEdit';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import { EventModel } from './models/Event';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from "react-router";

const useStyles = makeStyles((theme: Theme) => {
  return {
    layout: {
      width: 'auto',
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
      },
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    sidebar: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    fab: {
      margin: 0,
      top: 'auto',
      bottom: 20,
      left: 'auto',
      position: 'fixed' as 'fixed',
      zIndex: 100,
    },
    cancelFab: {
      right: 80,
    },
    saveFab: {
      right: 20,
    },
  };
});

interface IEventEditProps {
  event: EventModel,
  onEditChange: (event: EventModel) => void,
}
function EventEdit(props: IEventEditProps) {
  const [event, setEvent] = useState(props.event);
  const [dirty, setDirty] = useState(false);
  const { onEditChange } = props;
  const history = useHistory();

  function handleChange(fieldName: string, newValue: string) {
    const updatedEvent = { ...event, [fieldName]: newValue };
    setEvent(updatedEvent);
    setDirty(true);
  }

  function handleCancel() {
    onEditChange(props.event)
  }

  function handleSave() {
    if (dirty) {
      fetch(`/api/event/${event.slug}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
          'event': event,
        }),
      }).then((response) => {
        if (!response.ok) {
          throw new Error(`${response.status}`);
        }
        if (response.status === 201) {
          response.text().then((location) => {
            history.push(location);
          });
        } else {
          onEditChange(event);
        }
      })
    } else {
      onEditChange(props.event);
    }
  }

  const classes = useStyles(props);
  return (
    <div>
      <Fab color="primary" aria-label="Cancel" className={`${classes.fab} ${classes.cancelFab}`} onClick={handleCancel}>
        <CancelIcon />
      </Fab>
      <Fab color="secondary" aria-label="Save" className={`${classes.fab} ${classes.saveFab}`} onClick={handleSave} disabled={!dirty}>
        <SaveIcon />
      </Fab>
      <Grid container spacing={1} className={classes.layout}>
        <EventForm event={event} handleChange={handleChange} />
        <Grid item md={4} xs={12}>
          <EventGuestsEdit event={event} />
        </Grid>
      </Grid>
    </div>
  );
}

export default EventEdit;
