// Tutorial examples:
// https://www.robinwieruch.de/complete-firebase-authentication-react-tutorial/
// https://firebase.google.com/docs/auth/web/start#web-version-8

import firebase from "firebase/app";
import "firebase/auth";

// Configure Firebase.
const config = {
  apiKey: "AIzaSyABrF__2tm70qINgoVnmLB_HHB7WmoCebQ",
  authDomain: "auth.minivite.org",
  databaseURL: "https://rzvite37.firebaseio.com",
  messagingSenderId: "164682877169",
  projectId: "rzvite37",
  storageBucket: "rzvite37.appspot.com"
};

firebase.initializeApp(config);
const firebaseAuth = firebase.auth();

export {
  firebaseAuth
};
