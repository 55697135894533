import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { firebaseAuth } from './firebase/firebase';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => {
  return {
    button: {
      margin: theme.spacing(1),
    },
    input: {
      display: 'none',
    },
  }
});

function VerifyEmail(props) {
  const [isSent, setIsSent] = useState(false);

  function onClick() {
    const user = firebaseAuth.currentUser;
    if (user) {
      setIsSent(true);
      user.sendEmailVerification({
        url: 'https://minivite.org',
      });
    }
  }

  const classes = useStyles(props);
  return (
    <div>
      <Button variant="contained" color="primary" disabled={isSent} className={classes.button} onClick={onClick}>
        Verify your email
      </Button>
      {isSent && (
        <p>
          Verification email sent.
          Check your email (spam folders included) for a confirmation.
          Refresh this page once you have verified your email.
        </p>)}
    </div>
  )
}
export default VerifyEmail;
