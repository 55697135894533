import React from 'react';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => {
  return {
    layout: {
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
      },
    }
  };
});

function Tos(props) {
  const classes = useStyles(props);
  return (
    <div className={classes.layout}>
      <h1>MINIVITE.org Terms of Use</h1>

      <p>These Terms of Use govern your use of the MINIVITE.org website, as well as any mobile version of the website, and any tools or applications provided by MINIVITE.org (collectively the “Services”).  If you do not agree to these terms and conditions, you must immediately cease using the Services.</p>

      <p>MINIVITE.org is intended for adults, and should not be used by anyone under the age of 13.</p>

      <h2>Privacy</h2>
      <p>To use MINIVITE.org you must provide us with an email address and a name (though it does not need to be your legal name).  You understand that MINIVITE.org uses Google Cloud Platform services, so Google may also obtain information about your use of MINIVITE.org.  Google’s use of data is subject to its privacy policy.</p>

      <p>We do not require that you provide us any other personal information, and we encourage you not to provide any private or personally identifying information in connection with using MINIVITE.org.  You acknowledge that MINIVITE.org is located in the United States, and waive any claim based on the inadequacy of data protection regulations in the United States.</p>

      <p>We will not sell or rent your email or any other personal information you choose to provide us, and will not disclose it except to the extent necessary to provide the Services to you, or as required by law.  MINIVITE.org uses cookies, including third party cookies like those set by Google.</p>

      <h2>User-Contributed Content</h2>
      <p>Using MINIVITE.org may involve sending communications and generating other content that is made available to other users.  You understand that the content you post on MINIVITE.org is not secret, and may be used by MINIVITE.org without providing you notice or compensation.</p>

      <p>You grant MINIVITE.org a nonexclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, create derivative works from, distribute, and display this content in any media. Text, graphics, data, and other content posted by users on MINIVITE.org are the sole responsibility of those users. This means that (a) MINIVITE.org does not control and is not responsible for the accuracy, integrity, or quality of user-created content, and (b) you, and not MINIVITE.org, are entirely responsible for all content you post or otherwise make available via MINIVITE.org. MINIVITE.org reserves the right to remove or edit user-created content, but shall not be obligated to do so on any particular basis.</p>

      <p>You may not post or otherwise make available via MINIVITE.org any content that is illegal, obscene, threatening, defamatory, or otherwise injurious to third parties. You may not impersonate any person or entity, or otherwise mislead as to the origin of your content. You will indemnify MINIVITE.org for all claims resulting from content you supply.</p>

      <p>You understand that MINIVITE.org does not have any obligation to monitor the communications between users, and is not responsible for any statements made by other users of MINIVITE.org.</p>

      <h2>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</h2>
      <p>THIS SITE IS PROVIDED BY MINIVITE.org ON AN "AS IS" AND "AS AVAILABLE" BASIS. MINIVITE.org MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, WHETHER BASED UPON THE NATURE OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR OTHERWISE, AS TO THE OPERATION OF THIS SITE, OR THE ACCURACY, TIMELINESS, OR COMPLETENESS OF INFORMATION, CONTENT, MATERIALS, OR SERVICES INCLUDED ON THIS SITE. YOU EXPRESSLY AGREE THAT YOUR USE OF THIS SITE AND ANY INFORMATION CONTAINED HEREIN IS AT YOUR SOLE RISK. TO THE EXTENT PERMITTED BY LAW, MINIVITE.org WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES ARISING FROM THE USE OF THIS SITE, AND OUR MAXIMUM LIABILITY FOR ALL OTHER DAMAGES WILL BE $100. IF THE FOREGOING LIMITATIONS ARE NOT PERMITTED UNDER APPLICABLE LAW, YOU AGREE THAT MINIVITE.org'S LIABILITY WILL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED.</p>


      <h2>Other Websites</h2>
      <p>We may provide links to other web sites, but we can't control what those sites say or do. MINIVITE.org is not responsible for other sites' content, information collection practices, or use of the information they collect. MINIVITE.org's links to other web sites do not constitute an endorsement of those sites or their content, owners, or posters.</p>

      <h2>Law and Disputes</h2>
      <p>These Terms will be subject to the law of the state of Washington, without respect to its rules regarding conflict of laws. Except to the extent either of us is seeking specific performance, any dispute associated with these Terms will be resolved by arbitration, conducted by a single arbitrator, in Seattle, Washington (or such other location as we agree). The arbitration will be conducted by the American Arbitration Association ("AAA") under its rules and procedures, including the AAA's Supplementary Procedures for Consumer-Related Disputes (as applicable),</p>

      <h2>Changes to Terms of Use</h2>
      <p>We may update these Terms of Use by posting a new version on this website, so check back if you're interested. Questions? Contact us at feedback@minivite.org.</p>
    </div>
  )
}
export default Tos;
