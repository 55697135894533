import moment from 'moment';
import { Guest } from './Guest';

export interface EventModel {
  slug: string;
  name: string;
  start_date: string;
  end_date: string;
  location: string;
  location_href: string;
  address: string;
  host: string;
  image_url: string;
  description: string;
  user_invite: Guest;
  owner: boolean;
}

export const getHumanDateTime = (startDate: string, endDate: string) => {
  const startDateMoment = moment(startDate);
  const endDateMoment = moment(endDate);
  const startDateFormatted = startDateMoment.format('LLLL');
  let endDateFormatted = '';
  if (endDateMoment.isSame(startDateMoment, 'day')) {
    endDateFormatted = endDateMoment.format('LT');
  } else {
    endDateFormatted = endDateMoment.format('LLLL');
  }

  return `${startDateFormatted} - ${endDateFormatted}`;
};

export const getHumanDate = (startDate: string, endDate: string) => {
  const startDateMoment = moment(startDate);
  const endDateMoment = moment(endDate);
  const startDateFormatted = startDateMoment.format('LL');
  if (endDateMoment.isSame(startDateMoment, 'day')) {
    return startDateFormatted;
  } else {
    const endDateFormatted = endDateMoment.format('LL');
    return `${startDateFormatted} - ${endDateFormatted}`;
  }
};
