import EventCard from './EventCard'
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import React, { useEffect, useState } from 'react';

function Events() {
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    fetch('/api/event',
      { credentials: 'include', })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`${response.status}`);
        }
        return response.json();
      })
      .then((eventObjects) => void setEvents(eventObjects))
      .finally(() => void setLoading(false));
  }, []);

  if (loading) return (
    <LinearProgress color='secondary' />
  );
  if (!events || !events.length) return (
    <div>No events found.</div>
  )
  return (
    <Grid container spacing={2} justifyContent='center' style={{ padding: 24 }}>
      {events.map(ev => (
        <Grid item xs={12} sm={6} lg={4} xl={3} key={ev.slug}>
          <EventCard event={ev} />
        </Grid>
      ))}
    </Grid>
  );
}
export default Events;
