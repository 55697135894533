import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  }
});

function ErrorPage(props) {
  const classes = useStyles(props);
  return (
    <div>
      <Paper className={classes.root}>
        <Typography variant="h5" component="h3">
          Not found.
        </Typography>
      </Paper>
    </div>
  );
}

export default ErrorPage;