import EventIcon from '@mui/icons-material/Event';
import Face from '@mui/icons-material/Face';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Place from '@mui/icons-material/Place';
import React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { EventModel } from './models/Event';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => {
  return {
    layout: {
      width: 'auto',
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
      },
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    main: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    sidebar: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    hero: {
      maxHeight: '100%',
      maxWidth: '100%',
    },
    fab: {
      margin: 0,
      top: 'auto',
      bottom: 20,
      left: 'auto',
      position: 'fixed' as 'fixed',
      zIndex: 100,
    },
    cancelFab: {
      right: 80,
    },
    saveFab: {
      right: 20,
    },
    title: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    titleInput: {
      fontSize: '3rem',
      textAlign: 'center' as 'center',
    },
  };
});

interface IEventFormProps {
  event: EventModel,
  handleChange: (fieldName: string, value: string) => void,
}
function EventForm(props: IEventFormProps) {
  const { event, handleChange } = props;
  const classes = useStyles(props);

  function onChange(changeEvent: React.ChangeEvent<any>) {
    handleChange(changeEvent.target.id, changeEvent.target.value);
  }
  function handleStartDateChange(val) {
    handleChange('start_date', val);
  }
  function handleEndDateChange(val) {
    handleChange('end_date', val);
  }
  return (
    <Grid item md={8} xs={12}>
      <Paper className={classes.main}>
        <DefaultTextField
          className={classes.title}
          id="name"
          key="name"
          label="Event Name"
          value={event.name}
          InputProps={{
            classes: {
              input: classes.titleInput,
            },
          }}
          onChange={onChange}
        />
        <List dense={true}>
          <ListItem>
            <ListItemIcon>
              <EventIcon />
            </ListItemIcon>
            <ListItemText primary={
              <div>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateTimePicker
                    renderInput={(props) => <DefaultTextField {...props} />}
                    label="Start Date &amp; Time"
                    value={event.start_date}
                    onChange={handleStartDateChange} />
                  <DateTimePicker
                    renderInput={(props) => <DefaultTextField {...props} />}
                    label="End Date &amp; Time"
                    value={event.end_date}
                    onChange={handleEndDateChange} />
                </LocalizationProvider>
              </div>}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Place />
            </ListItemIcon>
            <ListItemText primary={
              <div>
                <DefaultTextField
                  id="location"
                  label="Location"
                  value={event.location}
                  onChange={onChange}
                />
                <DefaultTextField
                  id="address"
                  label="Address"
                  value={event.address}
                  onChange={onChange}
                />
                <DefaultTextField
                  id="location_href"
                  label="Location URL"
                  value={event.location_href}
                  onChange={onChange}
                />
              </div>} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Face />
            </ListItemIcon>
            <ListItemText>
              <DefaultTextField
                id="host"
                label="Host"
                value={event.host}
                onChange={onChange}
              />
            </ListItemText>
          </ListItem>
        </List>
        <img className={classes.hero} src={event.image_url} title={event.name} alt='' />
        <div style={{ textAlign: "left" }}>
          <DefaultTextField
            id="description"
            label="Description (Markdown supported)"
            value={event.description}
            multiline
            onChange={onChange}
          />
        </div>
      </Paper>
    </Grid>
  );
}

function DefaultTextField(props) {
  return (<TextField margin="normal" variant="outlined" fullWidth {...props} />);
}
export default EventForm;
