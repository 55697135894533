import AttendingIcon from './AttendingIcon';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { AttendingStatus } from './models/AttendingStatus';
import { Guest } from './models/Guest';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => {
  return {
    button: {
      margin: theme.spacing(1),
      '&:disabled': {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        border: '1px solid #ff00000',
        borderColor: theme.palette.secondary.main,
      },
    },
    iconClass: {
      marginRight: theme.spacing(1),
    },
  }
});

interface IAttendingProps {
  userGuest: Guest,
  onAttendingChange: (userGuest: Guest) => void,
  slug: string
}

function Attending(props: IAttendingProps) {
  const [userGuest, setUserGuest] = useState(props.userGuest);
  const { onAttendingChange, slug } = props;

  function handleAttendanceChange(event: React.ChangeEvent<any>) {
    // AttendingStatus enum is an integer but .value is a string.
    const attending = parseInt(event.currentTarget.value, 10);
    const updatedUserGuest = { ...userGuest, 'attending': attending };
    setUserGuest(updatedUserGuest);
    onAttendingChange(updatedUserGuest);
    fetch(`/api/event/${slug}/invite`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        'attending': updatedUserGuest.attending,
        'additionalGuests': updatedUserGuest.additionalGuests
      }),
    });
  }

  const classes = useStyles(props);
  return (
    <div>
      {statuses.map(
        ({ key, description }) => (
          <Button key={key} value={key} color="primary" disabled={userGuest.attending === key} onClick={handleAttendanceChange}
            variant={userGuest.attending === key ? "contained" : "outlined"} className={classes.button}>
            <AttendingIcon attending={key} classes={{ iconClass: classes.iconClass }} />
            {description}
          </Button>
        ))}
    </div>
  );
}

const statuses = [
  { key: AttendingStatus.ATTENDING, description: 'Yes' },
  { key: AttendingStatus.NOT_ATTENDING, description: 'No' },
  { key: AttendingStatus.MAYBE, description: 'Maybe' },
];

export default Attending;