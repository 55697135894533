import './App.css';
import CssBaseline from '@mui/material/CssBaseline';
import LinearProgress from '@mui/material/LinearProgress';
import Main from './Main';
import MenuAppBar from './MenuAppBar';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { firebaseAuth } from './firebase/firebase';
import { ThemeProvider, Theme, StyledEngineProvider, createTheme, adaptV4Theme } from '@mui/material/styles';


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


const theme = createTheme(adaptV4Theme({
  palette: {
    primary: { main: '#333333' },
    secondary: { main: '#cd0000' },
  },
}));

function App() {
  const [signedIn, setSignedIn] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [canCreateEvents, setCanCreateEvents] = useState(false);
  const [loading, setLoading] = useState(true);

  function setUser(authUser: firebase.User, forceRefresh: boolean) {
    authUser.getIdToken(forceRefresh).then((token: string) => {
      // Add the token to the browser's cookies. The server will then be
      // able to verify the token against the API.
      // SECURITY NOTE: As cookies can easily be modified, only put the
      // token (which is verified server-side) in a cookie; do not add other
      // user information.
      document.cookie = `token=${token};path=/`;
      setSignedIn(true);
      setEmailVerified(authUser.emailVerified);
      setLoading(false);
      if (authUser.emailVerified) {
        fetch('/api/user', {
          method: 'POST',
          credentials: 'include',
        }).then((response) => {
          if (!response.ok) {
            throw new Error(`${response.status}`);
          }
          return response.json();
        })
          .then((userObject) => void setCanCreateEvents(userObject['can_create_events']))
      }
    });
  }

  useEffect(() => {
    firebaseAuth.onAuthStateChanged(authUser => {
      if (authUser) {
        // If the authUser has a verified email, make sure the token has the
        // same claim. If not, force a token refresh. This fixes an issue where
        // new email users would need to sign out and back in (or wait for
        // token expiry).
        if (authUser.emailVerified) {
          authUser.getIdTokenResult().then((idTokenResult) => {
            if (!idTokenResult.claims['email_verified']) {
              setUser(authUser, true);
            } else {
              setUser(authUser, false);
            }
          });
        } else {
          setUser(authUser, false);
        }
      } else {
        setSignedIn(false);
        setEmailVerified(false);
        setLoading(false);
        setCanCreateEvents(false);
        // Clear the token cookie.
        document.cookie = 'token=';
      }
    });
  }, []);

  if (loading) {
    return <LinearProgress />
  }
  return (
    <Router>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="App">
            <MenuAppBar signedIn={signedIn} canCreateEvents={canCreateEvents} />
            <Main signedIn={signedIn} emailVerified={emailVerified} canCreateEvents={canCreateEvents} />
          </div>
        </ThemeProvider>
      </StyledEngineProvider>
    </Router>
  );
}
export default App;
