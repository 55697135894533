import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MailIcon from '@mui/icons-material/Mail';
import React, { useEffect, useState } from 'react';
import { Guest, compare } from './models/Guest';
import { AttendingStatus } from './models/AttendingStatus'

interface EmailGuestsDialogProps {
  slug: string,
};

export function EmailGuestsDialog(props: EmailGuestsDialogProps) {
  const { slug } = props;
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [allGuests, setAllGuests] = useState<Array<Guest>>([]);
  const [guests, setGuests] = useState<Array<Guest>>([]);
  const [rsvpYes, setRsvpYes] = useState(false);

  useEffect(() => {
    fetch(`/api/event/${slug}/guests`,
      { credentials: 'include', })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`${response.status}`);
        }
        return response.json();
      })
      .then((guestsByStatusObject) => void setGuestEmails(guestsByStatusObject))
      .finally(() => void setLoading(false));
  }, [slug]);

  function setGuestEmails(guestsByStatusObject) {
    const guestObjects = [];
    for (const status in guestsByStatusObject) {
      Array.prototype.push.apply(guestObjects, guestsByStatusObject[status]);
    }
    guestObjects.sort(compare);
    setAllGuests(guestObjects);
    setGuests(guestObjects);
  }

  function selectGuests() {
    if (rsvpYes) {
      setRsvpYes(false);
      setGuests(allGuests);
    } else {
      setRsvpYes(true);
      setGuests(allGuests.filter(g => g.attending === AttendingStatus.ATTENDING));
    }
  }

  if (loading) return (
    <CircularProgress color="secondary" />
  )

  return (
    <div>
      <MailIcon onClick={() => { setOpen(true) }} />
      <Dialog
        fullWidth={true}
        open={open}
        aria-labelledby="email-guests-dialog-title"
      >
        <DialogTitle id="add-guests-title">Email Selected Guests</DialogTitle>
        <DialogContent>
          <FormGroup>
            <FormControlLabel
              label="Only include ATTENDING"
              control={
                <Checkbox checked={rsvpYes} onChange={selectGuests} name="yes" />
              }
            />
          </FormGroup>
          <DialogContentText>
            {guests.map((guest: Guest) => (
              <span key={guest.email}>{guest.email}<br /></span>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
