import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import React, { useState } from 'react';

interface IRemoveGuestsDialogProps {
  slug: string,
  guestsToRemove: Set<string>,
  successCallback: Function
};

export function RemoveGuestsDialog(props: IRemoveGuestsDialogProps) {
  const { slug, guestsToRemove, successCallback } = props;
  const [open, setOpen] = useState(false);

  function handleRemove() {
    // TODO(leewilliams): Batch guest API.
    for (const email of guestsToRemove) {
      fetch(`/api/event/${slug}/guests`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ 'email': email }),
      });
    }
    successCallback();
    setOpen(false);
  }

  if (!guestsToRemove.size) return null;
  return (
    <Paper>
      <Button variant="outlined" color="primary" onClick={() => { setOpen(true) }}>
        Remove Selected Guests
      </Button>
      <Dialog
        fullWidth={true}
        open={open}
        aria-labelledby="remove-guests-dialog-title"
      >
        <DialogTitle id="add-guests-title">Remove Selected Guests</DialogTitle>
        <DialogContent>
          Are you sure you want to remove {guestsToRemove.size} guests?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleRemove} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
