/**
 * Based on: https://material-ui.com/getting-started/page-layout-examples/blog/
 */

import Attending from './Attending';
import EventIcon from '@mui/icons-material/Event';
import EventGuests from './EventGuests';
import Face from '@mui/icons-material/Face';
import Grid from '@mui/material/Grid';
import GuestAvatar from './GuestAvatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Markdown from 'react-markdown'
import Paper from '@mui/material/Paper';
import Place from '@mui/icons-material/Place';
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { EventModel, getHumanDateTime } from './models/Event';
import { Guest } from './models/Guest';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => {
  return {
    layout: {
      width: 'auto',
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
      },
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    main: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    sidebar: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    description: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      textAlign: 'left' as 'left',
    },
    hero: {
      maxHeight: '100%',
      maxWidth: '100%',
    },
  };
});

interface IEventShowProps {
  event: EventModel,
}

function EventShow(props: IEventShowProps) {
  const { event } = props;
  const classes = useStyles(props);
  const [userGuest, setUserGuest] = useState<Guest>(event.user_invite);

  return (
    <Grid container spacing={1} className={classes.layout}>
      <Grid
        item md={8} xs={12}>
        <Paper className={classes.main}>
          <Typography component="h3" variant="h3" color="inherit" gutterBottom>
            {event.name}
          </Typography>
          <List dense={true}>
            <ListItemLink href={createEventCalendarLink(event.name, event.address, event.start_date, event.end_date)}>
              <ListItemIcon>
                <EventIcon />
              </ListItemIcon>
              <ListItemText primary={getHumanDateTime(event.start_date, event.end_date)} />
            </ListItemLink>
            <ListItemLink href={event.location_href || createEventLocationLink(event.address)}>
              <ListItemIcon>
                <Place />
              </ListItemIcon>
              <ListItemText primary={event.location} secondary={event.address} />
            </ListItemLink>
            <ListItem>
              <ListItemIcon>
                <Face />
              </ListItemIcon>
              <ListItemText>
                {event.host}
              </ListItemText>
            </ListItem>
          </List>
          <img className={classes.hero} src={event.image_url} title={event.name} alt='' />
          <Markdown source={event.description} className={classes.description} />
        </Paper>
      </Grid>
      <Grid item md={4} xs={12}>
        <Paper className={classes.sidebar}>
          <ListItem>
            <ListItemAvatar>
              <GuestAvatar guest={userGuest} />
            </ListItemAvatar>
            <ListItemText
              primary={userGuest.name ? userGuest.name : userGuest.email}
              secondary='Will you attend?'
            />
          </ListItem>
          <Attending slug={event.slug}
            userGuest={userGuest}
            onAttendingChange={setUserGuest} />
        </Paper>
        <EventGuests userGuest={userGuest} />
      </Grid>
    </Grid>
  );
}

function ListItemLink(props: any) {
  return <ListItem button component="a" {...props} />;
}

const createEventCalendarLink = (name: string, address: string, start_date: string, end_date: string) => {
  if (start_date && end_date) {
    const startDate = new Date(start_date).toISOString().replace(/-|:|\.\d\d\d/g, "");
    const endDate = new Date(end_date).toISOString().replace(/-|:|\.\d\d\d/g, "");
    return `https://www.google.com/calendar/render?action=TEMPLATE&text=${name}&location=${address}&dates=${startDate}%2F${endDate}`;
  }
}

const createEventLocationLink = (address: string) => {
  return `https://www.google.com/maps/place/${address}`
}

export default EventShow;
