import AddGuestsDialog from './AddGuestsDialog';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import GuestAvatar from './GuestAvatar';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import React, { useEffect, useState } from 'react';
import { RemoveGuestsDialog } from './RemoveGuestsDialog';
import { EventModel } from './models/Event';
import { Guest, getGuestName, compare } from './models/Guest';

const dateStringOpts = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
const renderDateString = (dateVal: string) => {
  return new Date(dateVal).toLocaleDateString('en-US', dateStringOpts)
}

function EventGuestsEdit(props: { event: EventModel }) {
  const [loading, setLoading] = useState(true);
  const [guests, setGuests] = useState<Array<Guest>>([]);
  const [selectedGuests, setSelectedGuests] = useState<Set<string>>(new Set())
  const { event } = props

  useEffect(() => {
    fetch(`/api/event/${event.slug}/guests`,
      { credentials: 'include', })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`${response.status}`);
        }
        return response.json();
      })
      .then((guestsByStatusObject) => {
        const guestObjects = [];
        for (const status in guestsByStatusObject) {
          Array.prototype.push.apply(guestObjects, guestsByStatusObject[status]);
        }
        guestObjects.sort(compare);
        setGuests(guestObjects);
      })
      .finally(() => void setLoading(false));
  }, [event.slug]);

  if (loading) return (
    <CircularProgress color="secondary" />
  )

  function handleSelectionChange(email: string, checked: boolean) {
    if (checked && !selectedGuests.has(email)) {
      const newSelected = new Set(selectedGuests);
      newSelected.add(email);
      setSelectedGuests(newSelected);
    }
    if (!checked && selectedGuests.has(email)) {
      const newSelected = new Set(selectedGuests);
      newSelected.delete(email);
      setSelectedGuests(newSelected);
    }
  }

  function addGuests(newGuests: Guest[]): void {
    const updatedGuests = guests.concat(newGuests);
    updatedGuests.sort(compare);
    setGuests(updatedGuests);
  }

  function removeGuests(): void {
    const filteredGuests = guests.filter((guest) => !selectedGuests.has(guest.email));
    setGuests(filteredGuests);
  }

  return (
    <Paper>
      <AddGuestsDialog slug={event.slug} successCallback={addGuests} />
      <List>
        {guests.map((guest: Guest) => (
          <ListItem key={guest.email}>
            <ListItemAvatar>
              <GuestAvatar guest={guest} />
            </ListItemAvatar>
            <ListItemText
              primary={getGuestName(guest)}
              secondary={guest.modified && renderDateString(guest.modified)}
            />
            <Checkbox
              checked={selectedGuests[guest.email]}
              onChange={(e) => { handleSelectionChange(guest.email, e.target.checked) }}
              value="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </ListItem>
        ))}
      </List>
      <RemoveGuestsDialog slug={event.slug} guestsToRemove={selectedGuests} successCallback={removeGuests}></RemoveGuestsDialog>
    </Paper>
  );
}
export default EventGuestsEdit;
