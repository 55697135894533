import React from 'react';
import Attending from './Attending';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { EventModel, getHumanDate } from './models/Event';
import { Link as RouterLink } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => {
  return {
    card: {
      minWidth: 275
    },
    actions: {
      justifyContent: 'center',
    },
  }
});

interface IEventCardProps { event: EventModel };

function EventCard(props: IEventCardProps) {
  const { event } = props;
  const classes = useStyles(props);
  return (
    <Card className={classes.card}>
      <CardActionArea component={RouterLink} to={"event/" + event.slug}>
        <CardMedia style={{ height: 0, paddingTop: '56.25%' }}
          image={event.image_url}
          title={event.name}
        />
        <Typography variant="h5" color="textPrimary" gutterBottom>
          {event.name}
        </Typography>
        <Typography color="textSecondary">
          {getHumanDate(event.start_date, event.end_date)}
        </Typography>
        <Typography color="textSecondary">
          {event.location}
        </Typography>
        <Typography color="textSecondary">
          Hosted by: {event.host}
        </Typography>
      </CardActionArea>
      <CardActions className={classes.actions}>
        <Attending slug={event.slug}
          userGuest={event.user_invite}
          onAttendingChange={() => { }} />
      </CardActions>
    </Card>
  );
}

export default EventCard;