import React, { useEffect } from 'react';
import firebase from "firebase/app";
import "firebase/auth";
import { firebaseAuth } from './firebase/firebase';

/**
 * Note: FirebaseUI is not compatible with the v9 modular SDK. The v9
 * compatibility layer (specifically, the app-compat and auth-compat packages)
 * permits the usage of FirebaseUI alongside v9, but without the app size
 * reduction and other benefits of the v9 SDK.
 */

const uiConfig = {
  callbacks: {
    signInSuccessWithAuthResult: () => false,
  },
  signInOptions: [
    // Comment out any lines corresponding to providers you did not check in
    // the Firebase console.
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    // firebase.auth.GithubAuthProvider.PROVIDER_ID,
    // firebase.auth.PhoneAuthProvider.PROVIDER_ID

  ],
  signInFlow: 'popup',
};

function Firebase() {
  useEffect(() => {
    // Import the css only on the client.
    require('firebaseui/dist/firebaseui.css');

    // User is signed out.
    // Initialize the FirebaseUI Widget using Firebase.
    const firebaseui = require('firebaseui');
    const ui = firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebaseAuth);
    // Show the Firebase login button.
    ui.start('#firebaseui-auth-container', uiConfig);
  }, []);

  return (
    <div className="Firebase">
      <div id="firebaseui-auth-container" />
    </div>
  );
}
export default Firebase;
