import CheckCircle from '@mui/icons-material/CheckCircle';
import Help from '@mui/icons-material/Help';
import HighlightOff from '@mui/icons-material/HighlightOff';
import React from 'react';
import { AttendingStatus } from './models/AttendingStatus';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => {
  return {
    iconClass: {},
    attending: {
      color: "#00a000",
    },
    not_attending: {
      color: "#ff0000",
    },
    maybe_attending: {
      color: "#eed202",
    },
  }
});

interface IAttendingIconProps { attending: AttendingStatus }
function AttendingIcon(props: IAttendingIconProps) {
  const { attending } = props;
  const classes = useStyles(props);
  switch (attending) {
    case AttendingStatus.ATTENDING:
      return (<CheckCircle className={`${classes.attending} ${classes.iconClass}`} />)
    case AttendingStatus.NOT_ATTENDING:
      return (<HighlightOff className={`${classes.not_attending} ${classes.iconClass}`} />)
    case AttendingStatus.MAYBE:
      return (<Help className={`${classes.maybe_attending} ${classes.iconClass}`} />)
  }
  return <></>
}

// Use React.memo here to avoid unnecessary rerendering on every icon.
export default React.memo(AttendingIcon);
