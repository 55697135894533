/* https://material-ui.com/demos/dialogs/ */

import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Guest } from './models/Guest';
import { parseOneAddress } from 'email-addresses';

interface IAddGuestsDialogProps {
  slug: string
  successCallback: (x: Guest[]) => void
};

function AddGuestsDialog(props: IAddGuestsDialogProps) {
  const { slug, successCallback } = props;
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [rawGuests, setRawGuests] = useState('');
  const [parsedGuests, setParsedGuests] = useState([]);

  function parseGuests() {
    // convert raw guests into parsedGuests.
    const newParsedGuests: Guest[] = [];
    for (let row of rawGuests.split('\n')) {
      row = row.replace(RegExp('(,$)'), '');
      const parsed = parseOneAddress(row);
      if (parsed) {
        newParsedGuests.push({ name: parsed['name'], email: parsed['address'] } as Guest)
      } else {
        // Attempt to parse as one tab-delimited entry (like copying from
        // google sheets).
        const parts = row.split('\t');
        if (parts.length === 2) {
          newParsedGuests.push({ name: parts[0], email: parts[1] } as Guest);
        }
      }
    }
    setParsedGuests(newParsedGuests);
  }

  function submitGuests() {
    // TODO(leewilliams): Batch guest API.
    for (const guest of parsedGuests) {
      fetch(`/api/event/${slug}/guests`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(guest),
      });
    }
    successCallback(parsedGuests);
  };

  function handleGuestsChange(event) {
    setRawGuests(event.target.value);
  }

  function handleNext() {
    const nextStep = activeStep + 1;
    setActiveStep(nextStep);
    if (nextStep === 1) {
      parseGuests();
    } else if (nextStep === 2) {
      submitGuests();
    } else if (nextStep === 3) {
      handleClose();
    }
  }

  function handleBack() {
    setActiveStep(activeStep - 1);
  }

  function handleClose() {
    setOpen(false);
    setActiveStep(0);
    setRawGuests('');
    setParsedGuests([]);
  }

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={() => { setOpen(true) }}>
        Add Multiple Guests
      </Button>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="add-guests-dialog-title"
      >
        <DialogTitle id="add-guests-title">Add Multiple Guests</DialogTitle>
        <DialogContent>
          {activeStep === 0 && (
            <div>
              <div>
                Paste guests to add. Currently supported formats:
                <ul>
                  <li>Address book "name &lt;email&gt;" (copy from Gmail)</li>
                  <li>Tab-delimited "name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;email" (copy from Google Sheets)</li>
                </ul>
              </div>
              <TextField
                autoFocus
                id="guests"
                label="Guests"
                type="text"
                fullWidth
                multiline
                value={rawGuests}
                onChange={handleGuestsChange}
              />
            </div>)}
          {activeStep === 1 && (
            // display parsed guests with edit abilities
            <List dense={true}>
              {parsedGuests.map((guest: any) => (
                <ListItem key={guest.email}>
                  <ListItemText
                    primary={guest.name}
                    secondary={guest.email}
                  />
                </ListItem>
              ))}
            </List>
          )}
          {activeStep === 2 && (
            // Confirmation of success
            <div>Succesfully added {parsedGuests.length} guests!</div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Stepper activeStep={activeStep}>
            {STEPS.map((label, _index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: { optional?: React.ReactNode } = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div>
            <Button disabled={activeStep === 0} onClick={handleBack}>
              Back
            </Button>
            <Button variant="contained" color="primary" onClick={handleNext}>
              {activeStep === STEPS.length - 1 ? "Close" : "Next"}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const STEPS = ['Paste', 'Verify', 'Add Guests'];

export default AddGuestsDialog;
