import EditIcon from '@mui/icons-material/Edit';
import ErrorPage from './ErrorPage';
import EventEdit from './EventEdit';
import EventShow from './EventShow';
import Fab from '@mui/material/Fab';
import LinearProgress from '@mui/material/LinearProgress';
import React, { useEffect, useState } from 'react';
import { EmailGuestsDialog } from './EmailGuestsDialog';
import { EventModel } from './models/Event';
import { EventParams } from './models/EventParams';
import { useHistory, useParams } from 'react-router-dom';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => {
  return {
    fab: {
      margin: 0,
      top: 'auto',
      bottom: 20,
      left: 'auto',
      position: 'fixed' as 'fixed',
    },
    mailFab: {
      right: 80,
    },
    editFab: {
      right: 20,
    },
  }
});

function Event(props) {
  const [event, setEvent] = useState<EventModel | null>(null);
  const [loading, setLoading] = useState(true);
  const { id, edit } = useParams<EventParams>();
  const history = useHistory();

  function beginEdit() {
    history.push(`/event/${id}/edit`);
  }

  function handleEditChange(event: EventModel) {
    setEvent(event);
    history.push(`/event/${id}`);
  }

  useEffect(() => {
    fetch(`/api/event/${id}`,
      { credentials: 'include', })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`${response.status}`);
        }
        return response.json();
      })
      .then((eventObject) => void setEvent(eventObject))
      .finally(() => void setLoading(false));
  }, [id]);

  const classes = useStyles(props);

  if (loading) {
    return <LinearProgress color="secondary" />
  }
  if (!event) {
    return <ErrorPage />
  }
  if (event.owner && edit) {
    return <EventEdit event={event} onEditChange={handleEditChange} />
  }
  return (
    <div>
      <EventShow event={event} />
      {event.owner && (
        <div>
          <Fab color="primary" aria-label="Mail" className={`${classes.fab} ${classes.mailFab}`}>
            <EmailGuestsDialog slug={event.slug}></EmailGuestsDialog></Fab>
          <Fab color="secondary" aria-label="Edit" className={`${classes.fab} ${classes.editFab}`}
            onClick={beginEdit}>
            <EditIcon />
          </Fab>
        </div >)
      }
    </div >
  )
}

export default Event;
