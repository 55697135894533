import ErrorPage from './ErrorPage';
import Event from './Event';
import Events from './Events';
import EventNew from './EventNew';
import Firebase from './Firebase';
import Tos from './Tos';
import VerifyEmail from './VerifyEmail';
import React from 'react';
import { Route, RouteComponentProps, RouteProps, Switch } from "react-router-dom";

interface PrivateRouteProps extends RouteProps {
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>,
  render?: any,
  signedIn: boolean,
  emailVerified: boolean,
}
type RenderComponent = (props: RouteComponentProps<any>) => React.ReactNode;

function PrivateRoute(props: PrivateRouteProps) {
  const { component: Component, render, signedIn, emailVerified, ...rest } = props;
  const renderComponent: RenderComponent = (routeProps) => {
    if (!signedIn) {
      return <Firebase />
    }
    if (!emailVerified) {
      return <VerifyEmail />
    }
    if (Component) {
      return <Component {...routeProps} />
    }
    if (render) {
      return render();
    }
  }
  return (
    <Route {...rest} render={renderComponent} />
  );
}

interface IMainProps { signedIn: boolean, emailVerified: boolean, canCreateEvents: boolean };
function Main(props: IMainProps) {
  const { signedIn, emailVerified, canCreateEvents } = props;
  return (
    <Switch>
      <Route path="/tos" component={Tos} />
      <PrivateRoute path="/" exact component={Events} signedIn={signedIn} emailVerified={emailVerified} />
      <PrivateRoute path="/event/new" render={() => (<EventNew canCreateEvents={canCreateEvents} />)} signedIn={signedIn} emailVerified={emailVerified} />
      <PrivateRoute path="/event/:id/:edit?" component={Event} signedIn={signedIn} emailVerified={emailVerified} />
      <Route component={ErrorPage} />
    </Switch>
  );
}
export default Main;
