import AccountCircle from '@mui/icons-material/AccountCircle';
import AddCircle from '@mui/icons-material/AddCircle';
import AppBar from '@mui/material/AppBar';
import Home from '@mui/icons-material/Home';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ThemeProvider, StyledEngineProvider, createTheme, adaptV4Theme } from '@mui/material/styles';
import { Link } from "react-router-dom";
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { firebaseAuth } from './firebase/firebase';


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


const useStyles = makeStyles((theme: Theme) => {
  return {
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
    root: {
      flexGrow: 1,
    },
    iconClass: {
      marginRight: theme.spacing(),
    },
  }
});

const titleTheme = createTheme(adaptV4Theme({
  typography: {
    fontFamily: [
      'Josefin Sans',
    ].join(','),
  },
}));

interface IMenuAppBarProps { signedIn: boolean, canCreateEvents: boolean }

function MenuAppBar(props: IMenuAppBarProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { signedIn, canCreateEvents } = props;
  const open = Boolean(anchorEl);
  const classes = useStyles(props)

  function handleMenu(event: React.FormEvent) {
    setAnchorEl(event.currentTarget as HTMLElement);
  }
  function handleClose() {
    setAnchorEl(null);
  }
  function handleSignOut() {
    firebaseAuth.signOut();
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton component={Link} to='/' color="secondary" size="large"><Home /></IconButton>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={titleTheme}>
              <Typography variant="h4" color="inherit" className={classes.grow}>
                MINIVITE.org
              </Typography>
            </ThemeProvider>
          </StyledEngineProvider>
          <div>
            <IconButton
              aria-owns={open ? 'menu-appbar' : undefined}
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              size="large">
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                horizontal: 'right',
                vertical: 'top',
              }}
              transformOrigin={{
                horizontal: 'right',
                vertical: 'top',
              }}
              open={open}
              onClose={handleClose}
            >
              {signedIn && (
                <MenuItem onClick={handleSignOut}><AccountCircle className={classes.iconClass} /> Sign out</MenuItem>
              )}
              {canCreateEvents && (
                <MenuItem component={Link} to='/event/new'>
                  <AddCircle className={classes.iconClass} /> Create New event
                </MenuItem>
              )}
              <MenuItem component={Link} to='/tos'>Terms of Service</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default MenuAppBar;
