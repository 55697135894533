import Avatar from '@mui/material/Avatar';
import React from 'react';
import { Guest } from './models/Guest';

function getInitials(name: string) {
  const names = name.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

interface IGuestAvatarProps { guest: Guest };
function GuestAvatar({ guest }: IGuestAvatarProps) {
  if (guest.user && guest.user.picture) {
    return (<Avatar alt={guest.email} src={guest.user.picture} />)
  }
  return (
    <Avatar alt={guest.email}>
      {guest.name ? getInitials(guest.name) :
        getInitials(guest.email)}
    </Avatar>
  )
}

export default GuestAvatar;