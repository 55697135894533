import { AttendingStatus } from './AttendingStatus';
import { User } from './User';

export interface Guest {
  name: string;
  email: string;
  additionalGuests: number;
  attending: AttendingStatus;
  modified: string;
  user: User;
}

export const getGuestName = (guest: Guest) => {
  let name = guest.name ? guest.name : guest.email;
  if (guest.additionalGuests) {
    name += ` (+${guest.additionalGuests} guests)`;
  }
  return name;
};

export function compare(a: Guest, b: Guest) {
  const nameA = a.name ? a.name.toUpperCase() : '';
  const nameB = b.name ? b.name.toUpperCase() : '';
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
}